<template>
  <div class="custom-footer">
    <LogoArea />
    <CenterArea />
    <RightArea />
  </div>
</template>

<script>
import LogoArea from "@/components/customFooter/LogoArea";
import CenterArea from "@/components/customFooter/CenterArea";
import RightArea from "@/components/customFooter/RightArea";
export default {
  name: "custom-footer",
  components: {
    LogoArea,
    CenterArea,
    RightArea,
  },
};
</script>

<style lang="scss">
.custom-footer {
  display: flex;
  background-color: var(--background-footer);
  height: 390px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  .to-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
  }
}

@media only screen and (max-width: 1224px) {
  .custom-footer {
    height: 290px;
  }
}
</style>
