<template>
  <div class="portal-footer">
    <cookie-law buttonText="Aceitar">
      <div slot="message" class="ck-msg">
        Nós utilizamos cookies para otimizar e aprimorar sua navegação do site,
        manter uma melhoria contínua no conteúdo oferecido e aperfeiçoar a
        experiência de nossos usuários. Todos os cookies, exceto os essenciais,
        necessitam de seu consentimento para serem executados. Para saber mais
        <router-link to="legal-notes">clique aqui</router-link>.
      </div>
    </cookie-law>
    <img
      class="portal-footer__logo"
      src="@/assets/images/logo-aqu.png"
      alt="logo"
    />
    <div class="portal-footer__right">
      <div class="portal-footer__links-area">
        <span>
          <img src="@/assets/images/square-location.png" alt="" />
        </span>
        <a
          href="https://www.facebook.com/prefeituradeaquidauana"
          target="_blank"
        >
          <img src="@/assets/images/fb.png" alt="" />
        </a>
        <a
          href="https://www.instagram.com/prefeituradeaquidauana/"
          target="_blank"
        >
          <img src="@/assets/images/insta.png" alt="" />
        </a>
        <a href="https://www.youtube.com/watch?v=axzuUG0KyDw" target="_blank">
          <img src="@/assets/images/youtube.png" alt="" />
        </a>
      </div>
      <div class="portal-footer__info">
        <div class="portal-footer__info__title">ATENDIMENTO</div>
        <div class="portal-footer__info__content">
          Atendimento de Segunda-feira a Sexta-feira das 07:00 as 13:00 horas
          (horário oficial do MS)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "portal-footer",
  components: { CookieLaw },
  data() {
    return {
      listaLinks: [
        "square-location.png",
        "square-mail.png",
        "square-phone.png",
        "square-fb.png",
        "square-insta.png",
      ],
    };
  },
};
</script>

<style lang="scss">
.portal-footer {
  background: #f2f2f2;
  padding: 0 70px;
  height: 187px;
  width: 100%;
  max-width: 1440px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .Cookie__button {
    border-radius: 5px !important;
    background-color: var(--background-color-secondary) !important;
    &:active,
    &:hover {
      background-color: var(--background-color-hover-buttons) !important;
    }
  }
  .ck-msg {
    text-align: justify;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    padding: 0 10px;
    height: 58px;
  }

  &__links-area {
    margin: 0 20px;
    img {
      cursor: pointer;
      margin: 0 5px;
      height: 50px;
    }
  }

  &__info {
    width: 285px;
    text-align: left;
    &__title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      // font-size: 12px;
      font-size: var(--font-size--small);
      line-height: 21px;
      color: var(--background-color-secondary);
    }
    &__content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      // font-size: 12px;
      font-size: var(--font-size--small);
      line-height: 18px;
      color: var(--background-color-secondary);
    }
  }
}

@media only screen and (max-width: 964px) {
  .portal-footer {
    padding: 20px 30px;
    flex-direction: column;
    align-items: center;
    height: auto;
    &__right {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__logo {
      padding: 0;
      height: 48px;
    }
    &__links-area {
      margin: 0;
    }
    &__info {
      &__title {
        padding-top: 10px;
      }
    }
  }
}
</style>
