<template>
  <div class="card" v-if="Object.keys(destaque).length > 0">
    <img
      :src="destaque.image || placeholder"
      fluid
      alt="Imagem Destaque"
      @error="placeholder"
    />
    <div class="text-back">
      <div class="text">
        <span class="news-time">{{
          destaque.not_data | formatDate("LL")
        }}</span>
        <span class="news-title">{{ destaque.not_titulo }}</span>
        <!-- <p class="news-data">
          <span v-html="destaque.not_texto" />
        </p> -->
        <span class="news-more">Leia mais</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    destaque: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  methods: {
    placeholder(e) {
      e.target.src = require(`@/assets/images/placeholder.jpg`);
    },
  },
};
</script>

<style lang="scss">
.card {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 100%;
    height: 539px;
  }
  .text-back {
    position: absolute;
    width: 95%;
    max-height: 60%;
    height: auto;
    background: var(--background-color-text-semitransparent);
    border-radius: 10px;
    margin-bottom: 25px;

    .text {
      display: flex;
      flex-direction: column;
      font-family: Poppins;
      font-style: normal;
      padding: 20px;
      height: 100%;
      .news-time {
        font-weight: bold;
        font-size: var(--font-size--default);
        // font-size: 14px;
        line-height: 21px;
        color: var(--color-text-news);
      }
      .news-title {
        font-weight: bold;
        // font-size: 21px;
        font-size: calc(var(--font-size--small) * 2);
        line-height: 27px;
        color: var(--background-color-secondary);
        padding: 12px 0;
      }
      .news-data {
        margin: 0;
        padding: 0;
        font-weight: normal;
        // font-size: 17px;
        font-size: var(--font-size--large);
        line-height: 24px;
        color: #3f3f3f;

        text-align: justify;
        text-justify: inter-word;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .news-more {
        font-weight: bold;
        // font-size: 14.6623px;
        font-size: var(--font-size--default);
        line-height: 22px;
        color: var(--background-color-secondary);
        padding: 5px 0;
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .card {
    img {
      width: 100%;
      height: 404px;
    }

    .text-back {
      max-height: 60%;
      height: auto;
      .text {
        max-height: 100%;
        overflow-y: auto;
        .news-time {
          // font-size: 12px;
          font-size: var(--font-size--small);
          line-height: 14px;
        }
        .news-title {
          // font-size: 16px;
          font-size: var(--font-size--large);
          line-height: 18px;
        }
        .news-data {
          // font-size: 12px;
          font-size: var(--font-size--small);
          line-height: 16px;
        }
        .news-more {
          // font-size: 12px;
          font-size: var(--font-size--small);
          line-height: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 936px) {
  .card {
    width: 100%;
    .text-back {
      max-height: 70%;
      .text {
        max-height: 100%;
        overflow-y: auto;
        .news-time {
          // font-size: 11px;
          font-size: var(--font-size--small);
          line-height: 16px;
        }
        .news-title {
          // font-size: 16px;
          font-size: var(--font-size--large);
          line-height: 18px;
        }
        .news-data {
          // font-size: 12px;
          font-size: var(--font-size--small);
          line-height: 16px;
        }
        .news-more {
          // font-size: 12px;
          font-size: var(--font-size--small);
          line-height: 12px;
        }
      }
    }
  }
}
</style>
