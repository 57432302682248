<template>
  <b-container class="licitacoes">
    <h2>Licitações</h2>
    <div class="licitacoes-body">
      <div class="licitacoes-list" v-loading="loading">
        <ul>
          <li
            :class="{
              'licitacoes-list__item': true,
              active: isActive(`${licita.id}`),
            }"
            v-for="licita in licitacoes"
            :key="licita.id"
          >
            <a
              :class="{
                'licitacoes-list__item-link': true,
                active: isActive(`${licita.id}`),
              }"
              @click.prevent="setActive(`${licita.id}`)"
              :href="`#${licita.categoria}`"
              >{{ licita.categoria }}</a
            >
          </li>
        </ul>
      </div>
      <div
        class="licitacoes-component"
        v-loading="loadingActive"
        v-if="!loading"
      >
        <h4>Título do Documento</h4>
        <ul>
          <li v-for="data in licitaActive.data" :key="data.id">
            <span @click="handleSubActive(data.id)"
              ><strong>{{ data.numero }}</strong> - {{ data.titulo }}</span
            >
          </li>
        </ul>

        <div class="pg" v-if="Object.keys(licitaActive).length > 0">
          <b-pagination
            class="pt-5"
            align="center"
            v-model="licitaActive.current_page"
            pills
            :total-rows="licitaActive.total"
            @change="handleChangePage"
            :per-page="licitaActive.per_page"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-1"
      :title="modalTitle"
      v-model="modalOpen"
      ok-only
      ok-title="Fechar"
    >
      <div class="my-4 custom-content" v-loading="loadingModal">
        <ul v-if="modalContent.length > 0">
          <li v-for="item in modalContent" :key="item.id">
            <a :href="item.arquivo" target="_blank">{{ item.titulo }}</a>
          </li>
        </ul>
        <span v-else-if="!loadingModal"
          >Não foram encontrados resultados para essa busca.</span
        >
      </div>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "licitações",
  data() {
    return {
      loading: false,
      loadingActive: false,
      loadingModal: false,
      licitacoes: [],
      licitaActive: [],

      activeItem: "",
      open: false,
      modalContent: [],
      modalTitle: "",
    };
  },
  computed: {
    modalOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.open = value;
      },
    },
  },
  methods: {
    async getLicitacao() {
      this.loading = true;
      this.licitacoes = [];
      const res = await this.$axios.get(`licitacao`).catch((e) => {
        console.error("Licitacao", e);
      });
      if (res.status === 200) {
        this.licitacoes = res.data;
        this.setActive(this.licitacoes[0].id);
      }
      this.loading = false;
    },
    async getLicitacaoActive(id, pg = 1) {
      this.loadingActive = true;
      const res = await this.$axios
        .get(`licitacao/${id}?page=${pg}&offset=10`)
        .catch((e) => {
          console.error("Licitacao", e);
        });
      if (res.status === 200) {
        this.licitaActive = res.data || [];
      }
      this.loadingActive = false;
      window.scrollTo(0, 0);
    },
    handleChangePage(pg) {
      this.getLicitacaoActive(this.activeItem, pg);
    },
    async getSub(id) {
      this.loadingModal = true;
      this.modalContent = [];
      const res = await this.$axios
        .get(`licitacao/${this.activeItem}/${id}`)
        .catch((e) => {
          console.error("Licitacao", e);
        });
      if (res.status === 200) {
        this.modalContent = res.data;
      }
      this.loadingModal = false;
    },
    handleSubActive(id) {
      this.getSub(id);
      this.modalTitle = this.licitaActive?.data?.filter(
        (item) => item.id === id
      )[0].numero;
      this.modalOpen = true;
    },
    isActive(menuItem) {
      return this.activeItem.toString() === menuItem.toString();
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.getLicitacaoActive(menuItem);
    },
  },

  created() {
    this.getLicitacao();
  },
};
</script>

<style lang="scss">
.licitacoes {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);
  h4,
  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  &-body {
    display: flex;
  }

  &-list {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        background: var(--background-color-secondary);
        padding: 20px;
        border-radius: 0;
        margin: 0 1px 0 0;
        font-style: normal;
        font-weight: 400;
        border-top: 1px solid #dddddd;
        width: 300px;

        &:first-child {
          border-top: none;
        }
        & a {
          color: #87a8cc;
          &.active {
            color: #1f387f;
          }
        }

        &.active {
          background: #87a8cc;
        }
      }
    }
  }

  &-component {
    padding: 0 20px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        border-top: 1px solid #dddddd;
        padding: 10px 0;
        text-align: justify;
        &:first-child {
          border-top: none;
        }
        span {
          color: #007bff;
          text-decoration: none;
          background-color: transparent;
          cursor: pointer;
          &:hover {
            color: #0056b3;
            text-decoration: underline;
          }
        }
      }
    }
    .pg {
      ul {
        li {
          border: none;
        }
      }
    }
  }
}
.custom-content {
  ul {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    li {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .licitacoes {
    &.container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-body {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
</style>
