<template>
  <div class="portal-header">
    <img
      class="portal-header__logo"
      src="@/assets/images/logo-black.png"
      alt="logo"
      @click="toSite"
    />
    <div class="portal-header__login-area">
      <div class="portal-header__login-area__title">PORTAL DE SERVIÇOS</div>
      <!-- <div class="portal-header__login-area__content">
        <span class="texto">FAÇA LOGIN NO PORTAL</span>
        <span class="login md-8">
          <b-button variant="primary">LOGIN</b-button>
        </span>
        <span class="md-8">
          <b-button variant="primary">CADASTRO </b-button>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "portal-header",
  methods: {
    toSite() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.portal-header {
  padding: 0 80px;
  background: var(--background-color-secondary);
  height: 137px;
  width: 100%;
  max-width: 1440px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logo {
    padding: 0 20px;
    height: 80px;
    cursor: pointer;
  }

  &__login-area {
    display: flex;
    flex-direction: column;
    width: 410px;

    &__title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      // font-size: 36px;
      font-size: calc(var(--font-size--large) * 3);
      line-height: 49px;
      color: #efefef;
    }

    &__content {
      display: flex;
      align-items: center;
      .texto {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: var(--font-size--large);
        line-height: 20px;
        text-align: center;
        color: #efefef;
      }

      button {
        margin: 0;
        background: var(--color-buttons-links-blue);
        border-color: var(--background-color-secondary);
        color: var(--background-color-secondary);
        width: 147px !important;
        height: 37px;
        padding: 0;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        font-size: var(--font-size--large);
        line-height: 26px;
        border-radius: 8px;
        &:hover {
          background-color: var(--background-color-secondary);
          color: var(--color-buttons-links-blue);
        }
      }

      .login {
        margin: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .portal-header {
    padding: 0 70px;
    &__logo {
      padding: 0 10px;
      height: 70px;
    }

    &__login-area {
      width: 390px;
      &__title {
        // font-size: 36px;
        font-size: calc(var(--font-size--large) * 3);
        line-height: 40px;
      }

      &__content {
        .texto {
          // font-size: 13px;
          font-size: var(--font-size--small);
          line-height: 16px;
          font-weight: bold;
        }

        button {
          width: 137px !important;
          // font-size: 14px;
          font-size: var(--font-size--default);
        }
      }
    }
  }
}
@media only screen and (max-width: 964px) {
  .portal-header {
    padding: 20px 20px;
    flex-direction: column;
    align-items: center;
    height: auto;
    &__logo {
      height: 48px;
    }

    &__login-area {
      width: 100%;
      &__title {
        padding-top: 10px;
        // font-size: 30px;
        font-size: calc(var(--font-size--large) * 2.3);
        line-height: 40px;
      }

      &__content {
        justify-content: center;
        .texto {
          // font-size: 13px;
          font-size: var(--font-size--small);
          line-height: 16px;
          font-weight: bold;
          width: auto;
          max-width: 80px;
        }

        button {
          width: 107px !important;
          // font-size: 14px;
          font-size: var(--font-size--default);
        }
      }
    }
  }
}
</style>
