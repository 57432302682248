<template>
  <div class="center-area">
    <cookie-law buttonText="Aceitar">
      <div slot="message" class="ck-msg">
        Nós utilizamos cookies para otimizar e aprimorar sua navegação do site,
        manter uma melhoria contínua no conteúdo oferecido e aperfeiçoar a
        experiência de nossos usuários. Todos os cookies, exceto os essenciais,
        necessitam de seu consentimento para serem executados. Para saber mais
        <router-link to="legal-notes">clique aqui</router-link>.
      </div>
    </cookie-law>
    <div class="center-area__enderecos">
      <div class="superior">ENDEREÇO</div>
      <div class="inferior">
        Luiz da Costa Gomes, 711<br />
        Bairro Cidade Nova Aquidauana/MS <br />CEP: 79200-000
      </div>
    </div>
    <div class="center-area__contato">
      <div class="superior">CONTATO</div>
      <div class="inferior">
        (67) 3240-1400 <!-- <br />
        atendimento@aquidauana.ms.gov.br -->
      </div>
    </div>

    <div class="social-icons">
      <a href="https://www.facebook.com/prefeituradeaquidauana" target="_blank">
        <img src="@/assets/images/fb.png" alt="" />
      </a>
      <a
        href="https://www.instagram.com/prefeituradeaquidauana/"
        target="_blank"
      >
        <img src="@/assets/images/insta.png" alt="" />
      </a>
      <a href="https://www.youtube.com/watch?v=axzuUG0KyDw" target="_blank">
        <img src="@/assets/images/youtube.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "center-area",
  components: { CookieLaw },
};
</script>

<style lang="scss">
.center-area {
  .Cookie__button {
    border-radius: 5px !important;
    background-color: var(--background-color-secondary) !important;
    &:active,
    &:hover {
      background-color: var(--background-color-secondary) !important;
    }
  }
  .ck-msg {
    text-align: justify;
  }
  &__enderecos,
  &__contato {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    // font-size: 16.8049px;
    font-size: var(--font-size--large);
    line-height: 25px;
    color: var(--background-color-primary);
    display: flex;
    flex-direction: column;
    text-align: left;
    .superior {
      font-weight: bold;
    }
    .inferior {
      font-weight: normal;
    }
  }
  &__contato {
    margin-top: 20px;
  }

  .social-icons {
    display: none;
  }
}

@media only screen and (max-width: 1224px) {
  .center-area {
    &__enderecos,
    &__contato {
      // font-size: 12px;
      font-size: var(--font-size--small);
      line-height: 20px;
    }
    &__contato {
      margin-top: 20px;
    }

    .social-icons {
      display: none;
    }
  }
}
@media only screen and (max-width: 936px) {
  .center-area {
    &__enderecos,
    &__contato {
      //font-size: 10px;
      font-size: calc(var(--font-size--large) * 0.75);
      line-height: 16px;
    }
    &__contato {
      margin-top: 16px;
    }

    .social-icons {
      display: block;
      margin: 10px;
      text-align: left;
      img {
        cursor: pointer;
        margin: 0 3px;
        height: 35px;
        width: 35px;
      }
    }
  }
}
</style>
