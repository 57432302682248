<template>
  <div class="mapa">
    <div class="mapa-open">
      <l-map
        style="height: 300px"
        :zoom="zoom"
        :center="center"
        :options="{ scrollWheelZoom: false }"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="markerLatLng"> </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "mapa",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    coord: {
      type: String,
      default: "-20.45907, -55.78145",
      required: true,
    },
  },
  computed: {
    center() {
      return [this.coord.split(",")[0], this.coord.split(",")[1]];
    },
    markerLatLng() {
      return [this.coord.split(",")[0], this.coord.split(",")[1]];
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapa {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  &-open {
    width: 90%;
    .vue2leaflet-map {
      border-radius: 20px;
    }
  }
}
</style>
