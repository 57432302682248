<template>
  <b-container class="search" v-loading="loadingSearch">
    <h4>Busca por: {{ termo }}</h4>
    <ul>
      <li v-for="data in busca.data" :key="data.id">
        <a
          v-if="data.tipo === 'licitacao'"
          :href="`#${data.id}`"
          target="_blank"
          @click.prevent="handleSubActive(data)"
          ><strong>Licitação / {{ data.numero }} </strong> -
          {{ data.titulo }}</a
        >
        <a
          v-else
          :href="`${data.url}/${data.id}/${slugify(data.titulo)}`"
          target="_blank"
          ><strong>{{
            data.tipo === "noticias" ? "Notícias" : data.tipo
          }}</strong>
          - {{ data.titulo }}</a
        >
      </li>
    </ul>
    <div v-if="Object.keys(busca).length > 0">
      <b-pagination
        align="center"
        v-model="busca.current_page"
        pills
        :total-rows="busca.total"
        @change="handleChangePage"
        :per-page="busca.per_page"
      ></b-pagination>
    </div>
    <b-modal
      id="modal-search"
      :title="modalTitle"
      v-model="modalOpen"
      ok-only
      ok-title="Fechar"
    >
      <div class="my-4 custom-content" v-loading="loadingModal">
        <ul v-if="modalContent.length > 0">
          <li v-for="item in modalContent" :key="item.id">
            <a :href="item.arquivo" target="_blank">{{ item.titulo }}</a>
          </li>
        </ul>
        <span v-else-if="!loadingModal"
          >Não foram encontrados resultados para essa busca.</span
        >
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "search",
  computed: {
    ...mapGetters(["loadingSearch"]),
    modalOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.open = value;
      },
    },
  },
  data() {
    return {
      loading: false,
      busca: {},
      termo: "",
      activeItem: "",
      loadingModal: false,
      open: false,
      modalContent: [],
      modalTitle: "",
    };
  },
  methods: {
    ...mapActions(["setLoadingSearch"]),
    async getSub(data) {
      this.loadingModal = true;
      this.modalContent = [];
      window.scrollTo({
        top: 0,
      });
      const res = await this.$axios
        .get(`licitacao/${data.cat}/${data.id}`)
        .catch((e) => {
          console.error("Licitacao", e);
        });
      if (res.status === 200) {
        this.modalContent = res.data;
      }
      this.loadingModal = false;
    },
    handleSubActive(data) {
      this.getSub(data);
      this.modalTitle = data.numero;
      this.modalOpen = true;
    },
    slugify(text) {
      return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
    },
    handleChangePage(pg) {
      this.getSearch(this.termo, pg);
    },
    async getSearch(termo, pg = 1) {
      this.setLoadingSearch(true);
      this.busca = {};
      const res = await this.$axios
        .get(`pesquisar?term=${termo}&page=${pg}`)
        .catch((e) => {
          console.error("Busca", e);
        });
      if (res.status === 200) {
        this.busca = res.data;
      }
      this.setLoadingSearch(false);
    },
  },
  created() {
    this.termo = this.$route.params.title;
    this.getSearch(this.termo);
  },

  beforeRouteUpdate(to, from, next) {
    this.termo = to.params.title;
    const pg = 1;
    this.getSearch(this.termo, pg);
    next();
  },
};
</script>

<style lang="scss">
.search {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  ul {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    li {
      padding: 10px;
    }
  }
}
</style>
