<template>
  <b-container class="portal" v-loading="loading">
    <PortalLinks :items="servicos" />
  </b-container>
</template>

<script>
import PortalLinks from "@/components/Portal/PortalLinks";
export default {
  name: "portal",
  components: {
    PortalLinks,
  },
  data() {
    return {
      servicos: [],
      loading: false,
    };
  },
  methods: {
    async getServicos() {
      this.loading = true;
      const res = await this.$axios.get("servicos").catch((e) => {
        console.error("Serviços", e);
      });
      if (res.status === 200) {
        this.servicos = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getServicos();
  },
};
</script>

<style lang="scss">
.portal {
  padding: 40px 40px;
}
</style>
