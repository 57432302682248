<template>
  <b-container class="prefeitura" v-loading="loading">
    <div class="prefeitura-content mb-5" v-if="Object.keys(prefeitura).length">
      <Topo :secretaria="prefeitura" />
      <Lista :secretaria="prefeitura" />
      <div class="see-all">
        <a href="/prefeitos" class="text-left"
          ><span class="all-links">Ver todos os Prefeitos</span></a
        >
      </div>
      <Mapa :coord="prefeitura.coordenada" />
      <Tabs :secretaria="prefeitura" />
    </div>
  </b-container>
</template>

<script>
import Topo from "../components/Secretarias/Topo.vue";
import Lista from "@/components/Secretarias/Lista.vue";
import Mapa from "../components/Secretarias/Mapa.vue";
import Tabs from "../components/Secretarias/Tabs.vue";

export default {
  components: {
    Topo,
    Lista,
    Mapa,
    Tabs,
  },
  name: "prefeitura",
  data() {
    return {
      loading: false,
      prefeitura: {},
    };
  },
  methods: {
    async getPrefeitura() {
      this.loading = true;
      this.secretaria = {};
      const res = await this.$axios.get("prefeitura").catch((e) => {
        console.error("Prefeitura", e);
      });
      if (res.status === 200) {
        this.prefeitura = res.data;
      }
      console.log(this.prefeitura);
      this.loading = false;
    },
  },
  created() {
    this.getPrefeitura();
  },
};
</script>

<style lang="scss">
.prefeitura {
  padding: 0;
  min-height: 300px;

  .see-all {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px 10px 5%;
  }

  .text-left {
    width: 200px;
    height: 30px;
    background-color: var(--background-color-secondary);
    color: var(--background-color-primary);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: var(--background-color-hover-buttons) !important;
      text-decoration: none;
    }
  }
}
</style>
