<template>
  <b-container class="legislacoes">
    <h2>Legislação</h2>
    <div class="legislacoes-body">
      <div class="legislacoes-list" v-loading="loading">
        <ul>
          <li
            :class="{
              'legislacoes-list__item': true,
              active: isActive(`${legisla.id}`),
            }"
            v-for="legisla in legislacoes"
            :key="legisla.id"
          >
            <a
              :class="{
                'legislacoes-list__item-link': true,
                active: isActive(`${legisla.id}`),
              }"
              @click.prevent="setActive(`${legisla.id}`)"
              :href="`#${legisla.titulo}`"
              >{{ legisla.titulo }}</a
            >
          </li>
        </ul>
      </div>
      <div
        class="legislacoes-component"
        v-loading="loadingActive"
        v-if="!loading"
      >
        <ul v-if="legislaActive.data && legislaActive.data.length">
          <li v-for="data in legislaActive.data" :key="data.id">
            <a :href="data.url" target="_blank">{{ data.titulo }}</a>
          </li>
        </ul>
        <span v-else-if="!loadingActive && !loading"
          >Não foram encontrados resultados para essa busca.</span
        >
        <div class="pg" v-if="legislaActive.data && legislaActive.data.length">
          <b-pagination
            class="pt-5"
            align="center"
            v-model="legislaActive.current_page"
            pills
            :total-rows="legislaActive.total"
            @change="handleChangePage"
            :per-page="legislaActive.per_page"
          ></b-pagination>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "legislacao",
  data() {
    return {
      loading: false,
      loadingActive: false,
      legislacoes: [],
      legislaActive: [],
      activeItem: "",
    };
  },
  methods: {
    async getLegislacao() {
      this.loading = true;
      this.legislacoes = [];
      const res = await this.$axios.get(`legislacao`).catch((e) => {
        console.error("Legislacao", e);
      });
      if (res.status === 200) {
        this.legislacoes = res.data;
        this.setActive(this.legislacoes[0].id);
      }
      this.loading = false;
    },
    async getLegislacaoActive(id, pg = 1) {
      this.loadingActive = true;
      window.scrollTo({
        top: 0,
      });
      const res = await this.$axios
        .get(`legislacao/${id}?page=${pg}`)
        .catch((e) => {
          console.error("Legislacao", e);
        });
      if (res.status === 200) {
        this.legislaActive = res.data;
      }
      this.loadingActive = false;
    },
    handleChangePage(pg) {
      this.getLegislacaoActive(this.activeItem, pg);
    },
    isActive(menuItem) {
      return this.activeItem.toString() === menuItem.toString();
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.getLegislacaoActive(menuItem);
    },
  },
  created() {
    this.getLegislacao();
  },
};
</script>

<style lang="scss">
.legislacoes {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);
  h4,
  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  &-body {
    display: flex;
  }

  &-list {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        background: var(--background-color-secondary);
        padding: 20px;
        border-radius: 0;
        margin: 0 1px 0 0;
        font-style: normal;
        font-weight: 400;
        border-top: 1px solid #dddddd;
        width: 300px;

        &:first-child {
          border-top: none;
        }
        & a {
          color: #87a8cc;
          &.active {
            color: #1f387f;
          }
        }

        &.active {
          background: #87a8cc;
        }
      }
    }
  }

  &-component {
    padding: 0 20px;
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        border-top: 1px solid #dddddd;
        padding: 10px 0;
        text-align: justify;
        &:first-child {
          border-top: none;
        }
        span {
          color: #007bff;
          text-decoration: none;
          background-color: transparent;
          cursor: pointer;
          &:hover {
            color: #0056b3;
            text-decoration: underline;
          }
        }
      }
    }
    .pg {
      ul {
        li {
          border: none;
        }
      }
    }
  }
}
.custom-content {
  ul {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    li {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .legislacoes {
    &.container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-body {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
</style>
