<template>
  <b-container class="home">
    <div class="wrapper">
      <CustomCarousel :items="carouselItems" />
      <Destaque />
      <GaleriaDestaque />
      <VideosDestaque />
      <LinksArea :data="servicos" title="SERVIÇOS" color="blue" />
      <LinksArea :data="links" title="LINKS" color="green" />
      <!-- <CardsDestaque :data="editaisItems" :handleClick="editaisDestaqueToModal" /> -->
      <Banner :bannerInfo="bannerInfo" />
      <!-- <CardsDestaque :data="arquivoItems" /> -->
    </div>
    <b-modal
      id="modal-1"
      :title="modalTitle"
      v-model="modalOpen"
      ok-only
      ok-title="Fechar"
    >
      <div class="my-4 custom-content" v-loading="loadingModal">
        <ul v-if="modalContent.length > 0">
          <li v-for="item in modalContent" :key="item.id">
            <a :href="item.arquivo" target="_blank">{{ item.titulo }}</a>
          </li>
        </ul>
        <span v-else-if="!loadingModal"
          >Não foram encontrados resultados para essa busca.</span
        >
      </div>
    </b-modal>

    <!-- Modal para imagem e link -->
    <b-modal
      id="auto-modal"
      v-model="autoModalOpen"
      hide-footer
      hide-header
      content-class="p-0 border-0 no-p"
      style="padding: 0;"
    >
      <b-button class="close-button" @click="autoModalOpen = false">
        <i class="fa fa-close"></i>
      </b-button>
      <a href="https://forms.gle/paDWRPPcbYhaPVD49" target="_blank">
        <img
          src="@/assets/images/Feed_PESQUISA-PLOA-2025.gif"
          class="img-fluid"
          alt="Pesquisa PLOA 2025"
        />
      </a>
    </b-modal>
  </b-container>
</template>

<script>
// @ is an alias to /src
import CustomCarousel from "@/components/CustomCarousel";
import Destaque from "@/components/Destaque";
import LinksArea from "@/components/LinksArea";
//import CardsDestaque from "@/components/CardsDestaque";
import Banner from "@/components/Banner";
import VideosDestaque from "@/components/VideosDestaque";
import GaleriaDestaque from "@/components/GaleriaDestaque";

export default {
  name: "Home",
  components: {
    CustomCarousel,
    Destaque,
    LinksArea,
    //CardsDestaque,
    Banner,
    VideosDestaque,
    GaleriaDestaque,
  },
  data() {
    return {
      carouselItems: [],
      bannerInfo: {},
      editaisItems: {
        cards: [],
        title: "EDITAIS EM DESTAQUE",
      },
      servicos: [],
      links: [],
      open: false,
      loadingModal: false,
      modalContent: [],
      modalTitle: "",
      arquivoItems: {
        cards: [
          {
            numero:
              "Lorem ipsum dolor sit amet, consectetuer elit adipiscing, sed diam nonummy nibh ",
            inferior: "Lorem ipsum dolor sit amet, consectetuer adipiscing ",
            icone: "objetos-17.png",
          },
          {
            numero:
              "Lorem ipsum dolor sit amet, consectetuer elit adipiscing, sed diam nonummy nibh ",
            titulo: "Lorem ipsum dolor sit amet, consectetuer adipiscing ",
            icone: "objetos-17.png",
          },
          {
            numero:
              "Lorem ipsum dolor sit amet, consectetuer elit adipiscing, sed diam nonummy nibh ",
            titulo: "Lorem ipsum dolor sit amet, consectetuer adipiscing ",
            icone: "objetos-17.png",
          },
          {
            numero:
              "Lorem ipsum dolor sit amet, consectetuer elit adipiscing, sed diam nonummy nibh ",
            titulo: "Lorem ipsum dolor sit amet, consectetuer adipiscing ",
            icone: "objetos-17.png",
          },
        ],
        title: "ARQUIVOS",
      },
      autoModalOpen: false,
    };
  },
  computed: {
    modalOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.open = value;
      },
    },
  },
  methods: {
    async getCarousel() {
      const res = await this.$axios.get("carousel").catch((e) => {
        console.error("carousel", e);
      });
      if (res.status === 200) {
        this.carouselItems = res.data;
      }
    },
    async getBanner() {
      const res = await this.$axios.get("banner").catch((e) => {
        console.error("banner", e);
      });
      if (res.status === 200) {
        this.bannerInfo = res.data;
      }
    },
    async getEditaisDestaque() {
      const res = await this.$axios.get("licitacao/destaque").catch((e) => {
        console.error("licitacao", e);
      });
      if (res.status === 200) {
        this.editaisItems.cards = res.data;
      }
    },
    async getServicos() {
      const res = await this.$axios.get("servicos?type=1").catch((e) => {
        console.error("Serviços", e);
      });
      if (res.status === 200) {
        this.servicos = res.data;
      }
    },
    async getLinks() {
      const res = await this.$axios.get("servicos?type=2").catch((e) => {
        console.error("Links", e);
      });
      if (res.status === 200) {
        this.links = res.data;
      }
    },

    async getSub(licitaId, id) {
      this.loadingModal = true;
      this.modalContent = [];
      const res = await this.$axios
        .get(`licitacao/${licitaId}/${id}`)
        .catch((e) => {
          console.error("Licitacao", e);
        });
      if (res.status === 200) {
        this.modalContent = res.data;
      }
      this.loadingModal = false;
    },
    editaisDestaqueToModal(data) {
      this.getSub(data.licitacao_categoria_id, data.id);
      this.modalTitle = data.numero;
      this.modalOpen = true;
    },
  },
  created() {
    this.getCarousel();
    this.getEditaisDestaque();
    this.getBanner();
    this.getServicos();
    this.getLinks();
    this.autoModalOpen = false; // Abrir a modal automaticamente ao iniciar a aplicação
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0;
  // background-color: #ffffff;
  background-color: var(--background-color-primary);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
}

.close-button b-icon {
  font-size: 1.5em;
}

.b-modal {
  .modal-content {
    border: none;
  }
  .modal-header,
  .modal-footer {
    display: none;
  }
}
</style>

<style lang="scss">
.no-p {
  .modal-body {
    padding: 0 !important;
  }
}
</style>