<template>
  <div class="tabs-card">
    <span v-html="cardText"></span>
    <!-- <span class="tabs-card-title">
      {{ cardText.title }}
    </span>
    <p class="tabs-card-text">
      {{ cardText.texto }}
    </p> -->
  </div>
</template>

<script>
export default {
  name: "tabs-card",
  props: {
    cardText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.tabs-card {
  width: 100%;
  height: 100%;
  min-height: 300px;
  background: var(--background-color-light);
  text-align: left;
  padding: 70px 20px 20px 70px;
  color: var(--color-text-tabs);

  &-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    // font-size: 25.7674px;
    font-size: calc(var(--font-size--default) * 1.85);
    line-height: 39px;
    color: #4c4c4c;
  }

  &-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    // font-size: 25.7674px;
    font-size: calc(var(--font-size--default) * 1.85);
    line-height: 39px;
    color: #4c4c4c;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1224px) {
  .tabs-card {
    padding: 50px 20px 20px 70px;

    &-title {
      // font-size: 18px;
      font-size: calc(var(--font-size--default) * 1.3);
      line-height: 32px;
    }

    &-text {
      // font-size: 18px;
      font-size: calc(var(--font-size--default) * 1.3);
      line-height: 28px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .tabs-card {
    padding: 25px;

    &-title {
      // font-size: 14px;
      font-size: var(--font-size--default);
      line-height: 28px;
    }

    &-text {
      // font-size: 14px;
      font-size: var(--font-size--default);
      line-height: 21px;
    }
  }
}
</style>
