<template>
  <div id="portal">
    <PortalHeader />
    <router-view />
    <PortalFooter />
  </div>
</template>

<script>
import PortalFooter from "../components/Portal/PortalFooter.vue";
import PortalHeader from "../components/Portal/PortalHeader.vue";
export default {
  components: {
    PortalHeader,
    PortalFooter,
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
#portal {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 0 3px 2px #fafafa;
}

@media only screen and (max-width: 1224px) {
  #portal {
    max-width: 1023px;
  }
}
</style>
