<template>
  <div class="logo-area">
    <img class="logo" :src="getImgUrl('objetos-31.png')" alt="" />
  </div>
</template>

<script>
export default {
  name: "logo-area",
  methods: {
    getImgUrl(icone) {
      return require(`../../assets/images/${icone}`);
    },
  },
};
</script>

<style lang="scss">
.logo-area {
  .logo {
    height: 180px;
  }
}

@media only screen and (max-width: 936px) {
  .logo-area {
    .logo {
      height: 110px;
    }
  }
}
</style>
