import {
	initializeApp
} from 'firebase/app'

const firebaseConfig = {
	apiKey: 'AIzaSyDr_HofogcDiCJdG4FMDb3L-LAztzLjJU8',
	authDomain: 'prefeitura-aquidauna-ms.firebaseapp.com',
	projectId: 'prefeitura-aquidauna-ms',
	storageBucket: 'prefeitura-aquidauna-ms.appspot.com',
	messagingSenderId: '940824892218',
	appId: '1:940824892218:web:a089c7a99bbbda6b6db603',
	measurementId: 'G-XYH5SY9CSK'
}

const appFire = initializeApp(firebaseConfig)

export const app = appFire
