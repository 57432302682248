<template>
  <div id="app">
    <router-view key="$route.path" />
    <back-to-top bottom="5px" right="0" visibleoffset="200">
      <i class="fa fa-chevron-up"></i>
    </back-to-top>
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import BackToTop from "vue-backtotop";

export default {
  name: "app",
  components: {
    BackToTop,
  },
  methods: {
    addLink() {
      var selection = window.getSelection();
      const credito = document.location.href.match(/noticia/gm);

      let pagelink = `<br>Crédito:  ${
        credito?.length ? "Imprensa " : ""
      } Prefeitura de Aquidauana - MS. url:  ${document.location.href}`;

      var htmlDiv = document.createElement("div");
      for (var i = 0; i < selection.rangeCount; ++i) {
        htmlDiv.appendChild(selection.getRangeAt(i).cloneContents());
      }
      var selectionHTML = htmlDiv.innerHTML;

      let copytext = selectionHTML + pagelink;

      let newdiv = document.createElement("div");

      newdiv.style.position = "absolute";
      newdiv.style.left = "-99999px";

      document.body.appendChild(newdiv);
      newdiv.innerHTML = copytext;
      selection.selectAllChildren(newdiv);

      window.setTimeout(function () {
        document.body.removeChild(newdiv);
      }, 100);
    },
  },
  mounted() {
    document.addEventListener("copy", this.addLink);
  },
};
</script>

<style lang="scss">
/* Define styles for the default root window element */
:root {
  --background-color-primary: #ffffff;
  --background-color-secondary: #334995;
  --background-color-hover: #4a598e;
  --background-color-light: #dfe7f1;
  --background-color-light-green: #dff1ed;
  --background-color-semitransparent: rgba(51, 72, 148, 0.69);
  --background-color-text-semitransparent: rgba(255, 255, 255, 0.6);
  --background-color-hover-buttons: #4d66bf;
  --color-buttons-links-blue: #60c3ef;
  --color-buttons-links-green: #60efc4;
  --color-buttons-green: #33954f;
  --color-link-active: #e3e3fd;
  --background-footer: #172242;
  --color-shadow: #fafafa;
  --accent-color: #cacaca;
  --text-primary-color: #222;
  --color-text-news: #000000;
  --color-text-title: #2c456a;
  --color-card-tabs-title: #87a8cc;
  --color-text-title-secondary: #1c1c1b;
  --color-text-tabs: #000000;
  --color-text-destaques: #494949;
  --element-size: 4rem;
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color-primary: #1e1e1e;
  --background-color-secondary: #ffe000;
  --background-color-hover: #c3aa51;
  --background-color-light: #000000;
  --background-color-light-green: #000000;
  --background-color-semitransparent: rgba(148, 127, 51, 0.69);
  --background-color-text-semitransparent: rgba(57, 28, 28, 0.6);
  --background-color-hover-buttons: #bfa64d;
  --color-buttons-links-blue: #ef8c60;
  --color-buttons-links-green: #ef608b;
  --color-buttons-green: #953379;
  --color-link-active: #fff7b7;
  --background-footer: #ffe000;
  --color-shadow: #050505;
  --accent-color: #3f3f3f;
  --text-primary-color: #ddd;
  --color-text-news: #ffffff;
  --color-text-title-secondary: #fafafa;
  --color-card-tabs-title: #fff7b7;
  --color-text-title: #ffa000;
  --color-text-tabs: #ffffff;
  --color-text-destaques: #fafafa;
}

.larger-font > #app {
  font-size: 14px !important; /* (62.5/100) * 16px = 10px */
  --font-size--small: 14px; /* 14px */
  --font-size--default: 16px; /* 16px */
  --font-size--large: 18px; /* 24px */
  --font-size--extra-large: 83px; /* 24px */
}

.smaller-font > #app {
  font-size: 11px !important; /* (62.5/100) * 16px = 10px */
  --font-size--small: 10px; /* 14px */
  --font-size--default: 12px; /* 16px */
  --font-size--large: 14px; /* 24px */
  --font-size--extra-large: 81px; /* 24px */
}

#app {
  background: var(--background-color-primary);
  font-size: 12px !important;
  --font-size--small: 12px; /* 14px */
  --font-size--default: 14px; /* 16px */
  --font-size--large: 16px; /* 24px */
  --font-size--extra-large: 83px; /* 24px */
}

h2 {
  font-size: calc(var(--font-size--default) * 2.28) !important;
}

.vue-back-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
  background-color: var(--background-color-secondary);
  color: var(--background-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
