<template>
  <b-container class="mayors" v-loading="loading">
    <div class="galeria-title">
      <h3>Galeria de Prefeitos</h3>
    </div>
    <div class="mayors-images" v-if="Object.keys(galeria).length > 0">
      <ul>
        <li v-for="(item, index) in galeria.data" :key="index">
          <img class="icone" :src="getImgUrl(item.foto)" alt="" />
          <div class="content">
            <span class="content-mandato">{{ item.mandato }}</span>
            <p>{{ item.title }}</p>
          </div>
        </li>
        <!-- <li></li> -->
      </ul>
    </div>
  </b-container>
</template>

<script>
// import VLazyImage from "v-lazy-image/v2";
export default {
  name: "mayors",
  components: {
    // VLazyImage,
  },
  data() {
    return {
      galeria: [],
      loading: false,
    };
  },
  methods: {
    getImgUrl(foto) {
      if (foto) return foto;
      else return require(`../assets/images/placeholder_prefeitos.jpeg`);
    },
    async getGaleriaPrefeitos() {
      this.loading = true;
      this.galeria = [];
      const offset = 100;
      window.scrollTo({
        top: 0,
      });
      const res = await this.$axios
        .get(`v1/prefeitos?offset=${offset}`)
        .catch((e) => {
          console.error("Prefeitos", e);
        });
      if (res.status === 200) {
        this.galeria = res.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getGaleriaPrefeitos();
  },
};
</script>

<style lang="scss">
.mayors {
  min-height: 300px;
  padding: 30px 20px;
  font-family: Poppins;
  font-size: var(--font-size--default);
  color: var(--color-text-title);
  h3 {
    font-size: calc(var(--font-size--large) * 2);
    font-weight: bold;
    padding-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: calc(var(--font-size--large) * 1.5);
    }
  }

  &-images {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      display: grid;
      list-style: none;
      justify-content: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      padding: 0;

      img {
        max-height: 100%;
        width: 150px;
        object-fit: cover;
        vertical-align: bottom;
        border: 3px solid var(--background-color-secondary);
        border-radius: 5px;
      }
      li {
        text-align: left;
      }

      .content {
        text-align: left;
        max-width: 150px;
        &-mandato {
          background: var(--color-buttons-links-blue);
          border-radius: 5px;
          padding: 0 5px;
          font-weight: bold;
        }
      }
    }
    @media screen and (min-width: 768px) {
      ul {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media screen and (min-width: 1024px) {
      ul {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    @media screen and (min-width: 1280px) {
      ul {
        img {
          width: 200px;
        }
        .content {
          max-width: 180px;
        }
      }
    }
  }
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
