<template>
  <div class="portal-links">
    <div class="portal-links-list" v-for="(data, index) in items" :key="index">
      <a :href="data.url" :target="data.open">
        <div :class="['link-card', data.tipo === 2 ? 'green' : 'blue']">
          <img
            v-if="data.icone"
            :class="['icone', data.tipo === 2 ? 'green' : 'blue']"
            :src="data.icone"
            alt=""
          />
          <div :class="{ textos: true, 'has-icon': data.icone ? true : false }">
            <div class="superior">
              {{ splitTitle(data.titulo, "top") }}
            </div>
            <div :class="['inferior', data.tipo === 2 ? 'green' : 'blue']">
              {{ splitTitle(data.titulo, "bottom") }}
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "portal-links",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    splitTitle(title, pos) {
      const splitedTitle = title.split(" ").length;
      const size = splitedTitle > 2 ? 2 : 1;
      if (pos === "top") {
        return title.split(" ").slice(0, size).join(" ");
      } else if (pos === "bottom") {
        return title
          .split(" ")
          .slice(size, title.length - 1)
          .join(" ");
      }
    },
  },
};
</script>

<style lang="scss">
.portal-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  &-list {
    // margin: 40px 80px;
    .link-card {
      margin: 20px;
      // background: #dfe7f1;
      &.blue {
        background-color: var(--background-color-light);
        &:hover,
        &:active {
          background-color: var(--background-color-light);
        }
      }
      &.green {
        background-color: var(--background-color-light-green);
        &:hover,
        &:active {
          background-color: var(--background-color-light-green);
        }
      }

      color: var(--background-color-primary);
      width: 286px;
      height: 105px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: calc(var(--font-size--default) * 1.5);
      line-height: 32px;
      border-radius: 15px;
      display: flex;
      flex-direction: colum;
      justify-content: center;
      align-items: center;

      img {
        &.blue {
          filter: brightness(0) saturate(100%) invert(24%) sepia(52%)
            saturate(1264%) hue-rotate(199deg) brightness(95%) contrast(92%);
        }
        &.green {
          filter: brightness(0) saturate(100%) invert(48%) sepia(12%)
            saturate(2131%) hue-rotate(85deg) brightness(97%) contrast(87%);
        }
      }
      .icone {
        max-height: 60px;
        width: 60px;
        padding-right: 10px;
      }

      .textos {
        text-align: center;
        &.has-icon {
          text-align: left;
        }
        .superior {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size--large);
          line-height: 24px;
          color: var(--color-text-destaques);
        }
        .inferior {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: var(--font-size--large);
          line-height: calc(var(--font-size--large) * 1.2);
          &.blue {
            color: var(--background-color-secondary);
          }
          &.green {
            color: var(--color-buttons-green);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1224px) {
  .portal-links {
    &-list {
      .link-card {
        margin: 20px 10px;
      }
    }
  }
}
</style>
