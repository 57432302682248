<template>
  <b-container class="ouvidoria" v-loading="loading">
    <span v-html="dados.text"></span>
  </b-container>
</template>

<script>
export default {
  name: "ouvidoria",
  data() {
    return {
      dados: [],
      loading: false,
    };
  },
  methods: {
    async getOuvidoria() {
      this.loading = true;
      this.dados = [];
      const res = await this.$axios.get(`paginas/3`).catch((e) => {
        console.error("Ouvidoria", e);
      });
      if (res.status === 200) {
        this.dados = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getOuvidoria();
  },
};
</script>

<style lang="scss">
.ouvidoria {
  &.container {
    padding: 30px 40px;
    text-align: left;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px 20px;
      }
    }
  }
}
</style>
