<template>
  <b-container class="calendario" v-loading="loading">
    <br />
    <div class="table-responsive">
      <span v-html="dados.text"></span>
    </div>
    <br />
  </b-container>
</template>

<script>
export default {
  name: "CalendarioEventos",
  data() {
    return {
      dados: [],
      loading: false,
    };
  },
  methods: {
    async getDados() {
      this.loading = true;
      this.dados = [];
      const res = await this.$axios.get(`paginas/1`).catch((e) => {
        console.error("Ouvidoria", e);
      });
      if (res.status === 200) {
        this.dados = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getDados();
  },
};
</script>

<style lang="scss">
.calendario {
}
</style>
