<template>
  <a :href="data.url" :target="data.open">
    <div :class="['link-card', data.tipo === 2 ? 'green' : 'blue']">
      <img
        v-if="data.icone"
        :class="['icone', data.tipo === 2 ? 'green' : 'blue']"
        :style="data.style"
        :src="data.icone"
        alt=""
      />
      <div :class="{ textos: true, 'has-icon': data.icone ? true : false }">
        <div class="superior">
          {{ splitTitle(data.titulo, "top") }}
        </div>
        <div :class="['inferior', data.tipo === 2 ? 'green' : 'blue']">
          {{ splitTitle(data.titulo, "bottom") }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "link-card",
  props: ["data"],
  methods: {
    getImgUrl(icone) {
      return require(`../../assets/images/${icone}`);
    },
    splitTitle(title, pos) {
      const splitedTitle = title.split(" ").length;
      const size = splitedTitle > 2 ? 2 : 1;
      if (pos === "top") {
        return title.split(" ").slice(0, size).join(" ");
      } else if (pos === "bottom") {
        return title
          .split(" ")
          .slice(size, title.length - 1)
          .join(" ");
      }
    },
  },
};
</script>

<style lang="scss">
.link-card {
  &.blue {
    background-color: var(--background-color-secondary);
  }
  &.green {
    background-color: var(--color-buttons-green);
  }
  border-radius: 10px;
  width: 296px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    &.green {
      filter: brightness(0) saturate(100%) invert(82%) sepia(55%) saturate(422%)
        hue-rotate(96deg) brightness(97%) contrast(93%);
    }
  }
  .icone {
    max-height: 60px;
    width: 60px;
    padding-right: 10px;
  }

  .textos {
    text-align: center;
    &.has-icon {
      text-align: left;
    }
    .superior {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      // font-size: 16px;
      font-size: var(--font-size--large);
      line-height: 24px;
      color: var(--background-color-primary);
    }
    .inferior {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: calc(var(--font-size--large) * 1.1);
      // font-size: 17px;
      // color: #60c3ef;
      &.blue {
        color: var(--color-buttons-links-blue);
      }
      &.green {
        color: var(--color-buttons-links-green);
      }
    }
  }
}

@media only screen and (max-width: 1224px) and( min-width: 572px) {
  .link-card {
    // background-color: #334995;
    border-radius: 10px;
    width: 215px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 10px;

    .icone {
      max-height: 60px;
      width: 60px;
      padding: 10px;
    }

    .textos {
      text-align: center;
      &.has-icon {
        text-align: left;
      }
      .superior {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        // font-size: 11px;
        font-size: calc(var(--font-size--small) * 0.9);
        line-height: 14px;
        color: var(--background-color-primary);
      }
      .inferior {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        // font-size: 14px;
        font-size: var(--font-size--large);
        line-height: 14px;
        color: var(--color-buttons-links-blue);
      }
    }
  }
}
</style>
