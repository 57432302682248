<template>
  <div class="videos-destaque" v-loading="loading">
    <h2>VÍDEOS EM DESTAQUE</h2>
    <div class="videos-destaque__body">
      <ul class="videos-destaque__body__list">
        <li
          class="videos-destaque__body__item aviso"
          v-if="!active"
        >
          <img
            src="https://arquivos.aquidauana.ms.gov.br/pub/aviso-destaque-galeria.jpg"
            alt=""
          />
        </li>
        <li
          v-for="(video, index) in videosList"
          :key="index"
          class="videos-destaque__body__item"
          :class="{ 'disabled': !active }"
        >
          <LazyYoutubeVideo
            class="imgvideo"
            :src="video.url_embed"
            preview-image-size="hqdefault"
            :aspect-ratio="video.aspectRatio"
          />
        </li>
      </ul>
      <div class="see-all">
        <a href="/videos" class="text-right" v-if="videosList.length && active"
          ><span class="all-links">Ver todos</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
export default {
  name: 'videos-destaque',
  components: {
    LazyYoutubeVideo,
  },
  data() {
    return {
      videosList: [],
      loading: false,
      windowWidth: 0,
      active: true
    };
  },
  methods: {
    async getVideos() {
      this.loading = true;
      const offset = await this.getOffset();
      const res = await this.$axios
        .get(`videos/destaque?offset=${offset}`)
        .catch((e) => {
          console.error('Videos Destaque', e);
        });
      if (res.status === 200) {
        this.videosList = res.data;
        if (!this.active && this.videosList.length > 0) {
          this.videosList.pop(); // Remove o último item se active for false
        }
      }
      this.loading = false;
    },
    async getOffset() {
      const pageSize = this.windowWidth;
      let offset = 6;
      if (pageSize >= 1290) {
        offset = 8;
      }
      return offset;
    },
  },
  mounted() {
    this.getVideos();
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>

<style lang="scss">
.imgvideo {
  border-radius: 10px;
  img {
    border-radius: 10px !important;
  }
}
.videos-destaque {
  margin: 40px;
  padding-bottom: 20px;
  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: calc(var(--font-size--default) * 2);
    color: var(--background-color-secondary);
    text-align: left;
    padding-top: 0;
  }

  &__body {
    position: relative;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      padding-left: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
      list-style: none;
    }

    &__item {
      width: 300px;
      margin-top: 20px;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
  }
  .text-right {
    width: 112px;
    height: 30px;
    background-color: var(--background-color-secondary);
    color: var(--background-color-primary);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: var(--background-color-hover-buttons) !important;
      text-decoration: none;
    }
  }
}

.aviso {
  img {
    height: 170px;
    width: 300px;
    border-radius: 10px;
  }
}
</style>
