<template>
  <b-container class="legal-notes">
    <h2>Notas Legais Cookies</h2>
    <p>
      Nós utilizamos cookies para otimizar e aprimorar sua navegação do site,
      manter uma melhoria contínua no conteúdo oferecido e aperfeiçoar a
      experiência de nossos usuários. Todos os cookies, exceto os essenciais,
      necessitam de seu consentimento para serem executados.
    </p>
  </b-container>
</template>

<script>
export default {
  name: "legal-notes",
};
</script>

<style lang="scss">
.legal-notes {
  padding: 40px;
  p {
    padding: 40px;
    text-align: justify;
  }
}
</style>
