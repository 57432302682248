<template>
  <b-container class="lei-aldir" v-loading="loading">
    <h2>Lei Nº 14017/2020 - Lei Aldir Blanc</h2>
    <div class="lei-aldir__component">
      <ul>
        <li v-for="data in lei" :key="data.id">
          <a :href="data.arquivo" target="_blank">{{ data.titulo }}</a>
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "lei-aldir",
  data() {
    return {
      lei: [],
      loading: false,
    };
  },
  methods: {
    async getLei() {
      this.loading = true;
      this.lei = [];
      const res = await this.$axios.get(`lei-aldir-blanc`).catch((e) => {
        console.error("Lei Aldir Blanc", e);
      });
      if (res.status === 200) {
        this.lei = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getLei();
  },
};
</script>

<style lang="scss">
.lei-aldir {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);
  h4,
  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border-top: 1px solid #dddddd;
      padding: 10px 20px;
      text-align: justify;
      &:first-child {
        border-top: none;
      }
    }
  }
}

@media only screen and (max-width: 936px) {
}
</style>
