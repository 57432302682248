<template>
  <div class="right-area">
    <div class="right-area__atend">
      <div class="superior">ATENDIMENTO</div>
      <div class="inferior">
        De segunda à sexta, <br />
        das 07h às 13h
      </div>
      <div class="right-area__redes">
        <div class="superior">REDES SOCIAIS</div>
        <a
          href="https://www.facebook.com/prefeituradeaquidauana"
          target="_blank"
        >
          <img class="icone" :src="getImgUrl('objetos-32.png')" alt="" />
        </a>
        <a
          href="https://www.instagram.com/prefeituradeaquidauana/"
          target="_blank"
        >
          <img class="icone" :src="getImgUrl('objetos-33.png')" alt="" />
        </a>
        <a href="https://www.youtube.com/watch?v=axzuUG0KyDw" target="_blank">
          <img class="icone" :src="getImgUrl('objetos-35.png')" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "right-area",
  methods: {
    getImgUrl(icone) {
      return require(`../../assets/images/${icone}`);
    },
  },
};
</script>

<style lang="scss">
.right-area {
  &__atend,
  &__redes {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    // font-size: 16.8049px;
    font-size: var(--font-size--large);
    line-height: 20px;
    color: var(--background-color-primary);
    display: flex;
    flex-direction: column;
    text-align: left;
    .superior {
      font-weight: bold;
    }
    .inferior {
      font-weight: normal;
    }
  }
  &__redes {
    margin-top: 40px;
    .icone {
      height: 24px;
      width: 123px;
      margin-top: 5px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1224px) {
  .right-area {
    &__atend,
    &__redes {
      // font-size: 12px;
      font-size: var(--font-size--small);
      line-height: 16px;
    }
  }
}

@media only screen and (max-width: 936px) {
  .right-area {
    display: none;
  }
}
</style>
