<template>
  <b-container class="convenios">
    <h2>Convênios</h2>
    <div class="convenios-body">
      <div class="convenios-list" v-loading="loading">
        <ul>
          <li
            :class="{
              'convenios-list__item': true,
              active: isActive(`${item.id}`),
            }"
            v-for="item in convenios"
            :key="item.id"
          >
            <a
              :class="{
                'convenios-list__item-link': true,
                active: isActive(`${item.id}`),
              }"
              @click.prevent="setActive(`${item.id}`)"
              :href="`#${item.titulo}`"
              >{{ item.titulo }}</a
            >
          </li>
        </ul>
      </div>
      <div
        class="convenios-component"
        v-loading="loadingActive"
        v-if="!loading"
      >
        <h4>Título do Documento</h4>
        <ul>
          <li v-for="data in convenioActive" :key="data.id">
            <span @click="handleSubActive(data.id)">{{ data.titulo }}</span>
          </li>
        </ul>
      </div>
    </div>
    <b-modal
      id="modal-1"
      :title="modalTitle"
      v-model="modalOpen"
      ok-only
      ok-title="Fechar"
    >
      <div class="my-4 custom-content" v-loading="loadingModal">
        <ul v-if="modalContent.length > 0">
          <li v-for="item in modalContent" :key="item.id">
            <a :href="item.arquivo" target="_blank">{{ item.titulo }}</a>
          </li>
        </ul>
        <span v-else-if="!loadingModal"
          >Não foram encontrados resultados para essa busca.</span
        >
      </div>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  name: "licitações",
  data() {
    return {
      loading: false,
      loadingActive: false,
      loadingModal: false,
      convenios: [],
      convenioActive: [],

      activeItem: "",
      open: false,
      modalContent: [],
      modalTitle: "",
    };
  },
  computed: {
    modalOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.open = value;
      },
    },
  },
  methods: {
    async getConvenios() {
      this.loading = true;
      this.convenios = [];
      const res = await this.$axios.get(`convenios`).catch((e) => {
        console.error("Convênios", e);
      });
      if (res.status === 200) {
        this.convenios = res.data;
        this.setActive(this.convenios[0].id);
      }
      this.loading = false;
    },
    async getConveniosActive(id) {
      this.loadingActive = true;
      const res = await this.$axios.get(`convenios/${id}`).catch((e) => {
        console.error("Convenios", e);
      });
      if (res.status === 200) {
        this.convenioActive = res.data;
        console.log(this.convenioActive);
      }
      this.loadingActive = false;
    },
    async getSub(id) {
      this.loadingModal = true;
      this.modalContent = [];
      const res = await this.$axios
        .get(`licitacao/${this.activeItem}/${id}`)
        .catch((e) => {
          console.error("Licitacao", e);
        });
      if (res.status === 200) {
        this.modalContent = res.data;
      }
      this.loadingModal = false;
    },
    handleSubActive(id) {
      this.getSub(id);
      this.modalTitle = this.convenioActive?.filter(
        (item) => item.id === id
      )[0].titulo;
      this.modalOpen = true;
    },
    isActive(menuItem) {
      return this.activeItem.toString() === menuItem.toString();
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.getConveniosActive(menuItem);
    },
  },

  created() {
    this.getConvenios();
  },
};
</script>

<style lang="scss">
.convenios {
  &.container {
    padding: 30px 20px;
    text-align: left;
  }
  font-family: Poppins;
  // font-size: 14px;
  font-size: var(--font-size--default);
  h4,
  h2 {
    color: var(--color-text-title);
    font-weight: bold;
    text-align: left;
  }

  &-body {
    display: flex;
  }

  &-list {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        background: var(--background-color-secondary);
        padding: 20px;
        border-radius: 0;
        margin: 0 1px 0 0;
        font-style: normal;
        font-weight: 400;
        border-top: 1px solid #dddddd;
        width: 300px;

        &:first-child {
          border-top: none;
        }
        & a {
          color: #87a8cc;
          &.active {
            color: #1f387f;
          }
        }

        &.active {
          background: #87a8cc;
        }
      }
    }
  }

  &-component {
    padding: 0 20px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        border-top: 1px solid #dddddd;
        padding: 10px 0;
        text-align: justify;
        &:first-child {
          border-top: none;
        }
        span {
          color: #007bff;
          text-decoration: none;
          background-color: transparent;
          cursor: pointer;
          &:hover {
            color: #0056b3;
            text-decoration: underline;
          }
        }
      }
    }
    .pg {
      ul {
        li {
          border: none;
        }
      }
    }
  }
}
.custom-content {
  ul {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    li {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 936px) {
  .convenios {
    &-body {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
</style>
