<template>
  <div class="banner">
    <img :src="bannerInfo.imagem" class="hero-image" alt="" />
  </div>
</template>

<script>
export default {
  name: "banner",
  props: ["bannerInfo"],
};
</script>

<style lang="scss">
.banner {
  margin: 15px 5px 30px 5px;

  .hero-image {
    width: 100%;
    height: auto;
  }
}
</style>
