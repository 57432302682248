<template>
  <div id="tabs" class="tabs">
    <div class="container">
      <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('home')"
            :class="{ active: isActive('home') }"
            href="#home"
            >PRINCIPAL</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('competencia')"
            :class="{ active: isActive('competencia') }"
            href="#competencia"
            >COMPETÊNCIA</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('estrutura')"
            :class="{ active: isActive('estrutura') }"
            href="#estrutura"
            >ESTRUTURA</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('profissionais')"
            :class="{ active: isActive('profissionais') }"
            href="#profissionais"
            >PROFISSIONAIS</a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('home') }"
          id="home"
        >
          <TabsCard
            :cardText="secretaria.principal || 'Não há dados disponíveis'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('competencia') }"
          id="competencia"
        >
          <TabsCard
            :cardText="secretaria.competencia || 'Não há dados disponíveis'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('estrutura') }"
          id="estrutura"
        >
          <TabsCard
            :cardText="secretaria.estrutura || 'Não há dados disponíveis'"
          />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('profissionais') }"
          id="profissionais"
        >
          <TabsCard
            :cardText="secretaria.profissionais || 'Não há dados disponíveis'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabsCard from "@/components/Secretarias/TabsCard.vue";

export default {
  components: { TabsCard },
  name: "tabs",
  props: {
    secretaria: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      activeItem: "home",
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  justify-content: center;
  padding: 0;
  .container {
    width: 90%;
    padding: 0;
  }
  .nav-link {
    height: 75px;
    background: var(--background-color-hover);
    color: var(--color-card-tabs-title);
    border-radius: 0;
    margin: 0 1px 0 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    // font-size: 21.3328px;
    font-size: calc(var(--font-size--default) * 1.5);
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background: var(--background-color-secondary) !important;
      color: var(--color-card-tabs-title) !important;
    }
  }
}

@media only screen and (max-width: 1224px) {
  .tabs {
    .nav-link {
      height: 55px;
      // font-size: 16px;
      font-size: var(--font-size--large);
      line-height: 22px;
    }
  }
}
</style>
