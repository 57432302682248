<template>
  <b-container class="cidade-digital" v-loading="loading">
    <span v-html="dados.text" />
  </b-container>
</template>

<script>
export default {
  name: "CidadeDigital",
  data() {
    return {
      dados: [],
      loading: false,
    };
  },
  methods: {
    async getDados() {
      this.loading = true;
      this.dados = [];
      const res = await this.$axios.get(`paginas/2`).catch((e) => {
        console.error("cidade-digital", e);
      });
      if (res.status === 200) {
        this.dados = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getDados();
  },
};
</script>

<style lang="scss">
.cidade-digital {
  #noticia {
    display: flex;
    flex-direction: column;
    text-align: left;
    // align-items: center;

    .img-polaroid {
      width: 95%;
      img {
        width: 80%;
        height: auto;
      }
    }
    p {
      width: 85%;
    }
  }
  &.container {
    margin: 30px 40px;
    text-align: left;
  }
}
</style>
