<template>
  <div class="secretarias-topo">
    <div class="secretarias-topo-textos">
      <p class="secretarias-topo-textos__sigla">
        {{ secretaria.sigla || "" }}
      </p>
      <p class="secretarias-topo-textos__nome">
        {{ secretaria.titulo || "" }}
      </p>
    </div>
    <div class="secretarias-topo-fotos">
      <div class="secretarias-topo-fotos__left" v-if="secretaria.foto_pessoa">
        <img :src="secretaria.foto_pessoa" alt="" />
      </div>
      <div
        class="secretarias-topo-fotos__right"
        v-if="secretaria.foto_secretaria"
      >
        <img :src="secretaria.foto_secretaria" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "secretarias-topo",
  props: {
    secretaria: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
};
</script>

<style lang="scss">
.secretarias-topo {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-title-secondary);
  background: var(--background-color-light);
  height: 270px;
  width: 100%;
  text-align: left;
  &-textos {
    display: flex;
    padding: 20px;
    &__sigla {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: calc(var(--font-size--extra-large) * 0.75);
      line-height: 100%;
      margin: 0;
    }
    &__nome {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: calc(var(--font-size--default) * 2.4);
      line-height: calc(var(--font-size--default) * 2.4);
      margin: 0;
      padding-left: 20px;
    }
  }
  &-fotos {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    height: 900px;

    &__left,
    &__right {
      img {
        margin-left: 10px;
        height: 417px;
        width: 283px;
        border-radius: 15px;
        object-fit: cover;
        object-position: 80% 100%;
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .secretarias-topo {
    &-textos {
      align-items: center;
    }
    &-fotos {
      height: 750px;

      &__left,
      &__right {
        img {
          margin-left: 10px;
          height: 317px;
          width: 183px;
          border-radius: 15px;
          object-fit: cover;
          object-position: 50% 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .secretarias-out {
    height: auto;
  }
  .secretarias-topo {
    flex-direction: column;
    align-items: center;
    justify-content: initial;
    height: auto;

    &-textos {
      flex-direction: column;
      &__nome {
        text-align: center;
      }
    }
    &-fotos {
      display: flex;
      align-items: flex-end;
      justify-content: end;
      height: auto;
      &__left,
      &__right {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        img {
          object-fit: cover;
          object-position: 50% 50%;
          margin-left: 10px;
          height: 357px;
          width: 95%;
          border-radius: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .secretarias-topo {
    flex-direction: column;
    align-items: center;
    justify-content: initial;
    &-textos {
      flex-direction: column;
      &__nome {
        text-align: center;
      }
    }
    &-fotos {
      display: flex;
      align-items: flex-end;
      justify-content: end;
      height: auto;
      flex-direction: column;
      text-align: center;
      &__left,
      &__right {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        img {
          object-fit: contain;
          margin-left: 10px;
          height: 100%;
          width: 317px;
          border-radius: 15px;
        }
      }
    }
  }
}
</style>
