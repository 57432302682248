<template>
  <b-container>
    <!-- Image and text -->
    <b-navbar variant="faded" type="light" class="barra">
      <b-navbar-brand href="#">
        <img
          :src="logo"
          class="logo d-inline-block align-top"
          alt="logo"
          @click="toPortal"
        />
      </b-navbar-brand>
      <b-form-input
        class="input"
        v-model="text"
        placeholder="O QUE VOCÊ PROCURA?"
        v-on:keyup.enter="handleEnter"
        :disabled="loadingSearch"
      >
      </b-form-input>

      <div class="social-icons">
        <a
          href="https://www.facebook.com/prefeituradeaquidauana"
          target="_blank"
        >
          <img src="@/assets/images/fb.png" alt="" />
        </a>
        <a
          href="https://www.instagram.com/prefeituradeaquidauana/"
          target="_blank"
        >
          <img src="@/assets/images/insta.png" alt="" />
        </a>
        <a
          href="https://www.youtube.com/@PrefeituradeAquidauana"
          target="_blank"
        >
          <img src="@/assets/images/youtube.png" alt="" />
        </a>
      </div>
      <div class="access-icons">
        <span @click="accessibleFontSize('add')">
          <img :src="require(`../assets/images/access01.png`)" alt="" />
        </span>
        <span @click="accessibleFontSize('rm')">
          <img :src="require(`../assets/images/access02.png`)" alt="" />
        </span>
        <span
          @click="
            setTheme(userTheme === 'light-theme' ? 'dark-theme' : 'light-theme')
          "
        >
          <img :src="require(`../assets/images/access03.png`)" alt="" />
        </span>
        <!-- <span>
          <img :src="require(`../assets/images/access04.png`)" alt="" />
        </span>
        <span>
          <img :src="require(`../assets/images/access05.png`)" alt="" />
        </span> -->
      </div>
    </b-navbar>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Header",
  mounted() {
    const initUserTheme = this.getTheme() || "light-theme";
    this.setTheme(initUserTheme);
  },
  computed: {
    ...mapGetters(["loadingSearch"]),
  },
  data() {
    return {
      text: "",
      listaAccess: [
        "access01.png",
        "access02.png",
        "access03.png",
        "access04.png",
        "access05.png",
      ],
      lastSize: 0,
      lastValue: "",
      userTheme: "light-theme",
      logo: require(`@/assets/images/logo-aqu.png`),
    };
  },
  methods: {
    handleEnter() {
      console.log("enter was pressed", this.text);
      if (this.text.length >= 3) {
        this.$router.push(`/search/${this.text}`);
      }
    },
    handleLinkMedia() {
      console.log("media was clicked", this.text);
    },
    toPortal() {
      this.$router.push("/");
    },
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
      this.setLogo();
    },
    getTheme() {
      return localStorage.getItem("user-theme");
    },
    setLogo() {
      if (this.getTheme() === "dark-theme") {
        this.logo = require(`@/assets/images/logo-black.png`);
      } else this.logo = require(`@/assets/images/logo-aqu.png`);
    },

    accessibleFontSize(value) {
      if (this.lastValue === value && this.lastSize !== 0) {
        return;
      }
      this.lastValue = value;
      if (value === "add" && this.lastSize === 0) {
        this.lastSize = 1;
        document.body.classList.add("larger-font");
        document.body.classList.remove("smaller-font");
      } else if (value === "rm" && this.lastSize === 0) {
        this.lastSize = 2;
        document.body.classList.add("smaller-font");
        document.body.classList.remove("larger-font");
      } else {
        this.lastSize = 0;
        document.body.classList.remove("smaller-font");
        document.body.classList.remove("larger-font");
      }
    },
  },
};
</script>

<style lang="scss">
.container {
  max-width: 1440px !important;

  .navbar {
    padding: 0 !important;
  }
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  .barra {
    height: 156px;
    /* width: 1440px; */

    .logo {
      padding: 0 20px;
      height: 76px;
    }

    .input {
      max-width: 421px;
      height: 50px;
      background: url("../assets/images/search.svg") no-repeat right;
      background-size: 25px;
      background-position-x: 375px;
      background-color: var(--background-color-light);
      border-radius: 10px;
      border: none;
      padding-right: 50px;
      color: #87a8cc !important;
    }
    .form-control {
      // font-size: 19px;
      font-size: calc(var(--font-size--default) * 1.3);
    }
    .social-icons {
      img {
        cursor: pointer;
        margin: 0 3px;
        height: 50px;
        width: 50px;
      }
    }
    .access-icons {
      margin-left: 10px;
      img {
        cursor: pointer;
        margin: 0 2px;
        height: 50px;
      }
    }
  }
}
@media only screen and (max-width: 1224px) {
  /* Styles */
  .container {
    max-width: 1023px !important;
    .barra {
      height: 126px;
      /* width: 1440px; */
      .logo {
        padding: 0 20px;
        height: 56px;
      }

      .input {
        max-width: 330px;
        height: 40px;
        background: url("../assets/images/search.svg") no-repeat right;
        background-size: 25px;
        background-position-x: 290px;
        background-color: var(--background-color-light);
        border-radius: 10px;
        border: none;
        padding-right: 50px;
        color: #87a8cc !important;
      }
      .form-control {
        // font-size: 19px;
        font-size: var(--font-size--default);
      }
      .social-icons {
        margin-left: 10px;
        img {
          cursor: pointer;
          margin: 0 3px;
          height: 35px;
          width: 35px;
        }
      }
      .access-icons {
        margin-left: 10px;
        img {
          cursor: pointer;
          margin: 0 2px;
          height: 35px;
        }
      }
    }
  }
}

@media only screen and (max-width: 936px) {
  .access-icons {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .navbar {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
      margin-top: 20px;
    }
    .social-icons {
      display: none;
    }
  }
}
</style>
